const base = [
  { value: null, text: "Selecciona una opción" },
  {value:'BASEC310001',  text:'BASEC310001'},
  {value:'BASEC310002',  text:'BASEC310002'},
  {value:'BASEC310003',  text:'BASEC310003'},
  {value:'BASEC310004',  text:'BASEC310004'},
  {value:'BASEC310005',  text:'BASEC310005'},
  {value:'BASEC310006',  text:'BASEC310006'},
  {value:'BASEC310007',  text:'BASEC310007'},
  {value:'BASEC310008',  text:'BASEC310008'},
  {value:'BASEC310009',  text:'BASEC310009'},
  {value:'BASEC310010',  text:'BASEC310010'},
  {value:'BASEC310012',  text:'BASEC310012'},
  {value:'BASEC310013',  text:'BASEC310013'},
  {value:'BASEC310016',  text:'BASEC310016'},
  {value:'BASEC310017',  text:'BASEC310017'},
  {value:'BASEC310018',  text:'BASEC310018'},
  {value:'BASEC310019',  text:'BASEC310019'},
  {value:'BASEC310020',  text:'BASEC310020'},
  {value:'BASEC310021',  text:'BASEC310021'},
  {value:'BASEC310022',  text:'BASEC310022'},
  {value:'BASEC310023',  text:'BASEC310023'},
  {value:'BASEC310025',  text:'BASEC310025'},
  {value:'BASEC310027',  text:'BASEC310027'},
  {value:'BASEC310028',  text:'BASEC310028'},
  {value:'BASEC310029',  text:'BASEC310029'},
  {value:'BASEC310030',  text:'BASEC310030'},
  {value:'BASEC310031',  text:'BASEC310031'},
  {value:'BASEC310032',  text:'BASEC310032'},
  {value:'BASEC310033',  text:'BASEC310033'},
  {value:'BASEC310035',  text:'BASEC310035'},
  {value:'BASEC310036',  text:'BASEC310036'},
  {value:'BASEC310037',  text:'BASEC310037'},
  {value:'BASEC310038',  text:'BASEC310038'},
  {value:'BASEC310039',  text:'BASEC310039'},
  {value:'BASEC310041',  text:'BASEC310041'},
  {value:'BASEC310042',  text:'BASEC310042'},
  {value:'BASEC310043',  text:'BASEC310043'},
  {value:'BASEC310045',  text:'BASEC310045'},
  {value:'BASEC310046',  text:'BASEC310046'},
  {value:'BASEC310047',  text:'BASEC310047'},
  {value:'BASEC310049',  text:'BASEC310049'},
  {value:'BASEC310050',  text:'BASEC310050'},
  {value:'BASEC310051',  text:'BASEC310051'},
  {value:'BASEC310052',  text:'BASEC310052'},
  {value:'BASEC310053',  text:'BASEC310053'},
  {value:'BASEC310055',  text:'BASEC310055'},
  {value:'BASEC310056',  text:'BASEC310056'},
  {value:'BASEC310058',  text:'BASEC310058'},
  {value:'BASEC310061',  text:'BASEC310061'},
  {value:'BASEC310064',  text:'BASEC310064'},
  {value:'BASEC310065',  text:'BASEC310065'},
  {value:'BASEC310067',  text:'BASEC310067'},
  {value:'BASEC310068',  text:'BASEC310068'},
  {value:'BASEC310069',  text:'BASEC310069'},
  {value:'BASEC310075',  text:'BASEC310075'},
  {value:'BASEC310076',  text:'BASEC310076'},
  {value:'BASEC310077',  text:'BASEC310077'},
  {value:'BASEC310078',  text:'BASEC310078'},
  {value:'BASEC310079',  text:'BASEC310079'},
  {value:'BASEC310082',  text:'BASEC310082'},
  {value:'BASEC310094',  text:'BASEC310094'},
  {value:'BASEC310095',  text:'BASEC310095'},
  {value:'BASEC310096',  text:'BASEC310096'},
  {value:'BASEC310097',  text:'BASEC310097'},
  {value:'BASEC310101',  text:'BASEC310101'},
  {value:'BASEC310102',  text:'BASEC310102'},
  {value:'BASEC330002',  text:'BASEC330002'},
  {value:'BASEC330003',  text:'BASEC330003'},
  {value:'BASEC330008',  text:'BASEC330008'},
  {value:'BASEC330009',  text:'BASEC330009'},
  {value:'BASEC330010',  text:'BASEC330010'},
  {value:'BASEC330011',  text:'BASEC330011'},
  {value:'BASEC330012',  text:'BASEC330012'},
  {value:'BASEC330013',  text:'BASEC330013'},
  {value:'BASEC330014',  text:'BASEC330014'},
  {value:'BASEC330016',  text:'BASEC330016'},
  {value:'BASEC330018',  text:'BASEC330018'},
  {value:'BASEC330019',  text:'BASEC330019'},
  {value:'BASEC330020',  text:'BASEC330020'},
  {value:'BASEC330022',  text:'BASEC330022'},
  {value:'BASEC330023',  text:'BASEC330023'},
  {value:'BASEC330024',  text:'BASEC330024'},
  {value:'BASEC330025',  text:'BASEC330025'},
  {value:'BASEC330026',  text:'BASEC330026'},
  {value:'BASEC330027',  text:'BASEC330027'},
  {value:'BASEC330028',  text:'BASEC330028'},
  {value:'BASEC330030',  text:'BASEC330030'},
  {value:'BASEC330032',  text:'BASEC330032'},
  {value:'BASEC330033',  text:'BASEC330033'},
  {value:'BASEC330035',  text:'BASEC330035'},
  {value:'BASEC330038',  text:'BASEC330038'},
  {value:'BASEC330039',  text:'BASEC330039'},
  {value:'BASEC330040',  text:'BASEC330040'},
  {value:'BASEC330044',  text:'BASEC330044'},
  {value:'BASEC330046',  text:'BASEC330046'},
  {value:'BASEC330047',  text:'BASEC330047'},
  {value:'BASEC330049',  text:'BASEC330049'},
  {value:'BASEC330050',  text:'BASEC330050'},
  {value:'BASEC330051',  text:'BASEC330051'},
  {value:'BASEC330052',  text:'BASEC330052'},
  {value:'BASEC330053',  text:'BASEC330053'},
  {value:'BASEC330055',  text:'BASEC330055'},
  {value:'BASEC330056',  text:'BASEC330056'},
  {value:'BASEC330058',  text:'BASEC330058'},
  {value:'BASEC330059',  text:'BASEC330059'},
  {value:'BASEC330062',  text:'BASEC330062'},
  {value:'BASEC330063',  text:'BASEC330063'},
  {value:'BASEC330065',  text:'BASEC330065'},
  {value:'BASEC330067',  text:'BASEC330067'},
  {value:'BASEC330070',  text:'BASEC330070'},
  {value:'BASEC330071',  text:'BASEC330071'},
  {value:'BASEC330075',  text:'BASEC330075'},
  {value:'BASEN310001',  text:'BASEN310001'},
  {value:'BASEN310003',  text:'BASEN310003'},
  {value:'BASEN310008',  text:'BASEN310008'},
  {value:'BASEN310009',  text:'BASEN310009'},
  {value:'BASEN310013',  text:'BASEN310013'},
  {value:'BASEN310015',  text:'BASEN310015'},
  {value:'BASEN310018',  text:'BASEN310018'},
  {value:'BASEN310021',  text:'BASEN310021'},
  {value:'BASEN310022',  text:'BASEN310022'},
  {value:'BASEN310025',  text:'BASEN310025'},
  {value:'BASEN310026',  text:'BASEN310026'},
  {value:'BASEN310027',  text:'BASEN310027'},
  {value:'BASEN310028',  text:'BASEN310028'},
  {value:'BASEN310029',  text:'BASEN310029'},
  {value:'BASEN310030',  text:'BASEN310030'},
  {value:'BASEN330002',  text:'BASEN330002'},
  {value:'BASEN330003',  text:'BASEN330003'},
  {value:'BASEN330004',  text:'BASEN330004'},
  {value:'BASEN330005',  text:'BASEN330005'},
  {value:'BASEN330008',  text:'BASEN330008'},
  {value:'CORTE310001',  text:'CORTE310001'},
  {value:'CORTE310002',  text:'CORTE310002'},
  {value:'CORTE310003',  text:'CORTE310003'},
  {value:'CORTE310016',  text:'CORTE310016'},
  {value:'CORTE330003',  text:'CORTE330003'},
  {value:'CORTE330014',  text:'CORTE330014'},
  {value:'ENGON330003',  text:'ENGON330003'}
];

export { base };