<template>
  <section>
    <b-tabs>

      <b-tab title="Skus">
        <skus-list ref="historico-lineas-productivas"></skus-list>
      </b-tab>


      <b-tab title="Ingreso Nuevo Sku">
        <nuevo-sku-form @nuevo-sku-agregado="refreshHistoricoLineasProductivas"></nuevo-sku-form>
      </b-tab>
    </b-tabs>
  </section>
</template>
<script>
import
{
  BRow,
  BCol,
  BCard,
  BButton,
  BCardHeader,
  BFormFile,
  BCardTitle,
  BCardSubTitle,
  BCardText,
  BCardBody,
  BFormSelect,
  BIconNodeMinusFill,
  BTabs,
  BSpinner,
  BAlert,
  BTab,
  BLink,
  VBPopover,
  VBTooltip,
  BFormGroup,
  BFormInvalidFeedback

} from "bootstrap-vue";



import AgGridTable from '@/views/brunacomponents/ag-grid-table/AgGridTable.vue'

import NuevoSkuForm from "./NuevoSkuForm.vue";
import SkusList from "./SkusList.vue";


export default {
  data()
  {
    return {



    }



  },
  components: {
    BRow,
    BCol,
    BCard,
    BButton,
    BCardHeader,
    BFormFile,
    BCardTitle,
    BCardSubTitle,
    BCardText,
    BCardBody,
    BFormSelect,
    BTabs,
    VBPopover,
    VBTooltip,
    BTab,
    BLink,
    BAlert,
    BSpinner,
    //GoodTableBasic,
    BFormInvalidFeedback,
    BFormGroup,
    AgGridTable,


    NuevoSkuForm,
    SkusList
  },

  directives: {
    'b-tooltip': VBTooltip,
    "b-popover": VBPopover,
  },

  mounted()
  {

  },

  methods: {


    refreshHistoricoLineasProductivas()
    {

      this.$refs['historico-lineas-productivas'].refreshRows()

    }



  },
};
</script>

<style scoped>
.carga-data {
  margin-top: 4%;
}
</style>

