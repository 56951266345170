const punzon = [
  { value: null, text: "Selecciona una opción" },
  {value:'3 LISTONES',  text:'3 LISTONES'},
  {value:'3 LISTONES EBONY',  text:'3 LISTONES EBONY'},
  {value:'3 TABLONES',  text:'3 TABLONES'},
  {value:'4 LISTONES',  text:'4 LISTONES'},
  {value:'5 LISTONES',  text:'5 LISTONES'},
  {value:'ARUSHA',  text:'ARUSHA'},
  {value:'BAHIA',  text:'BAHIA'},
  {value:'BERLIN',  text:'BERLIN'},
  {value:'BRICK',  text:'BRICK'},
  {value:'CAIRO',  text:'CAIRO'},
  {value:'CARAVISTA',  text:'CARAVISTA'},
  {value:'CASPIO',  text:'CASPIO'},
  {value:'CUMARU',  text:'CUMARU'},
  {value:'CURVA',  text:'CURVA'},
  {value:'DENIA',  text:'DENIA'},
  {value:'DIAMANTE',  text:'DIAMANTE'},
  {value:'DUNAS',  text:'DUNAS'},
  {value:'ETRUSCA',  text:'ETRUSCA'},
  {value:'FESTIVAL',  text:'FESTIVAL'},
  {value:'GRAVA',  text:'GRAVA'},
  {value:'HARMORY',  text:'HARMORY'},
  {value:'HIDRAULICO',  text:'HIDRAULICO'},
  {value:'JADE',  text:'JADE'},
  {value:'JAZZ',  text:'JAZZ'},
  {value:'LAJA',  text:'LAJA'},
  {value:'LAJA GALA',  text:'LAJA GALA'},
  {value:'LAJA SCALA',  text:'LAJA SCALA'},
  {value:'LAJA ZEN',  text:'LAJA ZEN'},
  {value:'LINEAL',  text:'LINEAL'},
  {value:'LISO',  text:'LISO'},
  {value:'LUXUR',  text:'LUXUR'},
  {value:'MADERA',  text:'MADERA'},
  {value:'MAGMA',  text:'MAGMA'},
  {value:'MAJESTIC',  text:'MAJESTIC'},
  {value:'MARMENELLI',  text:'MARMENELLI'},
  {value:'NACAR',  text:'NACAR'},
  {value:'ONDAS',  text:'ONDAS'},
  {value:'PARMA',  text:'PARMA'},
  {value:'PARRAL',  text:'PARRAL'},
  {value:'PASSION',  text:'PASSION'},
  {value:'PEPELMA',  text:'PEPELMA'},
  {value:'PETRA',  text:'PETRA'},
  {value:'PIEDRA RIO',  text:'PIEDRA RIO'},
  {value:'QATAR',  text:'QATAR'},
  {value:'ROCKSTONE',  text:'ROCKSTONE'},
  {value:'ROMANCE',  text:'ROMANCE'},
  {value:'RUPANCO',  text:'RUPANCO'},
  {value:'SWING',  text:'SWING'},
  {value:'TAPARELLA',  text:'TAPARELLA'},
  {value:'TENTACION',  text:'TENTACION'},
  {value:'TOUJOURS',  text:'TOUJOURS'},
  {value:'WOODRIVER',  text:'WOODRIVER'},
  {value:'ZIG ZAG',  text:'ZIG ZAG'}
];


export { punzon };