const color=  [
  { value: null, text: "Selecciona una opción" },
  { value:"ARENA",  text: "ARENA"},
  { value:"AZUL",  text: "AZUL" },
  { value:"BEIGE",  text: "BEIGE"},
  { value:"BIANCO",  text: "BIANCO"},
  { value:"BLANCO",  text: "BLANCO"},
  { value:"BLUE",  text: "BLUE" },
  { value:"BROWN",  text: "BROWN"},
  { value:"CAFE",  text: "CAFE" },
  { value:"CAFE OSCURO",  text:  "CAFE OS"},
  { value:"CARAMELO",  text: "CARAMELO"},
  { value:"CARAMELO CAFE",  text: "CARAMELO CAFE"},
  { value:"DARK",  text: "DARK" },
  { value:"GOLDEN",  text: "GOLDEN"},
  { value:"GRAFITO",  text: "GRAFITO"},
  { value:"GREY",  text: "GREY" },
  { value:"GRIS",  text: "GRIS" },
  { value:"GRIS CLARO",  text:  "GRIS CLARO"},
  { value:"GRIS OSCURO",  text: "GRIS OSCURO"},
  { value:"HAYA",  text: "HAYA" },
  { value:"HONEY",  text: "HONEY"},
  { value:"HUESO",  text: "HUESO"},
  { value:"LADRILLO",  text: "LADRILLO"},
  { value:"LEGNO",  text: "LEGNO"},
  { value:"LIGHT",  text: "LIGHT"},
  { value:"MADERA",  text: "MADERA"},
  { value:"MARFIL",  text: "MARFIL"},
  { value:"MARINO",  text: "MARINO"},
  { value:"MARRON",  text: "MARRON"},
  { value:"MIEL",  text: "MIEL"},
  { value:"MIX",  text: "MIX"},
  { value:"MOKA",  text: "MOKA"},
  { value:"NARANJA",  text: "NARANJA"},
  { value:"NATURAL",  text: "NATURAL"},
  { value:"NEGRO",  text: "NEGRO"},
  { value:"NOGAL",  text: "NOGAL"},
  { value:"NUEZ",  text: "NUEZ"},
  { value:"NUT",  text: "NUT"},
  { value:"OCRE",  text: "OCRE"},
  { value:"OXIDO",  text: "OXIDO"},
  { value:"PARQUET",  text: "PARQUET"},
  { value:"PLATA",  text: "PLATA"},
  { value:"ROBLE",  text: "ROBLE"},
  { value:"ROJO",  text: "ROJO"},
  { value:"SAND",  text: "SAND"},
  { value:"TAUPE",  text: "TAUPE"},
  { value:"TOSTADO",  text: "TOSTADO"},
  { value:"VERDE",  text: "VERDE"},
  { value:"VIOLETA",  text: "VIOLETA"},
  { value:"VISON",  text: "VISON"},
  { value:"WHITE",  text: "WHITE"},
];

export { color };