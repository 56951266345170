const diseno_rodillos = [
  {value:null,  text:'No aplica'},
  {value:'BOSTON',  text:'BOSTON'},
  {value:'CANARIAS',  text:'CANARIAS'},
  {value:'CHAPADO',  text:'CHAPADO'},
  {value:'CONCEPT',  text:'CONCEPT'},
  {value:'STORM',  text:'STORM'},
  {value:'CIRUELO',  text:'CIRUELO'},
  {value:'LEGÑO',  text:'LEGÑO'},
  {value:'AVELLANO',  text:'AVELLANO'},
  {value:'OXIDUM',  text:'OXIDUM'},
  {value:'ATENAS',  text:'ATENAS'},
  {value:'BENEVENTO',  text:'BENEVENTO'},
  {value:'COLISEO',  text:'COLISEO'},
  {value:'DURCAL',  text:'DURCAL'},
  {value:'DURCAL-SILEA',  text:'DURCAL-SILEA'},
  {value:'ELVOS',  text:'ELVOS'},
  {value:'Floor',  text:'Floor'},
  {value:'GADOR',  text:'GADOR'},
  {value:'CHART',  text:'CHART'},
  {value:'PEPELMA',  text:'PEPELMA'},
  {value:'PORTO',  text:'PORTO'},
  {value:'REYNA',  text:'REYNA'},
  {value:'VICAR',  text:'VICAR'},
  {value:'TILO BEIGE',  text:'TILO BEIGE'},
  {value:'POLAR',  text:'POLAR'},
  {value:'PRISMA',  text:'PRISMA'},
  {value:'BALI',  text:'BALI'},
  {value:'DECO ART',  text:'DECO ART'},
  {value:'JADE',  text:'JADE'},
  {value:'FIORE',  text:'FIORE'},
  {value:'LUCIENNE',  text:'LUCIENNE'},
  {value:'MATRIX',  text:'MATRIX'},
  {value:'TIZIANO',  text:'TIZIANO'}
];

export { diseno_rodillos };