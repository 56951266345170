<template>
  <section>
    <BrunaForm
      ref="nuevoSkuRef"
      v-bind:fields="this.nuevo_sku.fields"
      :card="this.nuevo_sku.card"
      @change="nuevoSkuChange"
    ></BrunaForm>

    <b-card>
      <b-card-body>
        <b-button
          variant="primary"
          class="mt-4 float-right"
          @click="save"
          size="lg"
          >Guardar</b-button
        >
      </b-card-body>
    </b-card>
  </section>
</template>

<script>

import { VueAutosuggest } from 'vue-autosuggest'

import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

import BrunaForm from "@/views/brunacomponents/BrunaForm.vue";


import
{
  BRow,
  BCol,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BButton,
  BCard,
  BCardBody,
  BForm,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BFormDatepicker,
  BCardTitle,
  BCardSubTitle,
  VBTooltip,
  VBPopover
} from "bootstrap-vue";
import vSelect from 'vue-select';
import axios from "@axios";


import useApiServices from '@/services/useApiServices.js';
import { familia } from './familia.js';
import { punzon } from './punzon.js';
import { diseno_rodillos } from './disenorodillos.js';
import { color } from './color.js';
import { base } from './base.js';
import { engobe } from './engobe.js';

export default {

  directives: {
    'b-tooltip': VBTooltip,
    "b-popover": VBPopover,
  },

  props: {
    forecast: {
      type: Object,
      default: () => null
    }
  },

  data()
  {



    return {



      nuevo_sku: {
        card: {
          title: "Crear Nuevo Sku",
          subtitle: "",
          tooltip: ""
        },
        fields: [


          {
            label: "Sku (Código)",
            type: "input",
            id: 'codigo',
            rules: 'required',
            format: {
              xs: 4,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4
            },
            value: null,
            show: true
          },

          {
            label: "Nombre",
            type: "input",
            id: 'name',
            rules: 'required',
            format: {
              xs: 4,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4
            },
            value: null,
            show: true
          },


          {
            label: "Formato",
            type: "select",
            id: 'formato',
            rules: 'required',
            options: [
              { value: null, text: "Selecciona una opcion" },
              { value: "27x45", text: "27x45" },
              { value: "36x36", text: "36x36" },
              { value: "60x60", text: "60x60" },
              { value: "30x60", text: "30x60" },
              { value: "45x45", text: "45x45" },
              { value: "20x61", text: "20x61" },
              { value: "60x45", text: "60x45" }

            ],
            format: {
              xs: 4,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4
            },
            value: null
          },



          {
            label: "Estado",
            type: "select",
            id: 'status',
            rules: 'required',
            options: [
              { value: null, text: "Selecciona una opción" },
              { value: "Descontinuada", text: "Descontinuada" },
              { value: "En Linea", text: "en Linea" }

            ],
            format: {
              xs: 4,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4
            },
            value: null
          },


          {
            label: "Punzón",
            type: "select",
            id: 'punzon',
            rules: 'required',
            options: punzon,
            format: {
              xs: 4,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4
            },
            value: null,
            show: true
          },

          



          {
            label: "Base",
            type: "select",
            id: 'base',
            rules: 'required',
            options: base,
            format: {
              xs: 4,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4
            },
            value: null,
            show: true
          },

          {
            label: "Acabado",
            type: "select",
            id: 'acabado',
            rules: 'required',
            options: [
              { value: null, text: "Selecciona una opción" },
              { value: 'CRISTALINA', text: "CRISTALINA" },
              { value: 'MATE', text: "MATE" },
              { value: 'GRANILLADO', text: "GRANILLADO" },
              { value: 'BLANCO BRILLANTE', text: "BLANCO BRILLANTE" },
              { value: 'ANTIREFLEX', text: "ANTIREFLEX" },
              { value: 'SEMIBRILLO', text: "SEMIBRILLO" },
              { value: 'BIZCOCHO', text: "BIZCOCHO" },
              { value: 'BLANCO SEMIBRILLO', text: "BLANCO SEMIBRILLO" },
              { value: 'ANTISLIP', text: "ANTISLIP" },
              { value: 'BRILLANTE', text: "BRILLANTE" },
              { value: 'SEMIBRILLANTE', text: "SEMIBRILLANTE" },
              { value: 'PULIDO', text: "PULIDO" },
            ],
            format: {
              xs: 4,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4
            },
            value: null,
            show: true
          },

         
          {
            label: "Familia",
            type: "select",
            id: 'familia',
            rules: 'required',
            options: familia,
            format: {
              xs: 4,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4
            },
            value: null,
            show: true
          },
 
          {
            label: "Color",
            type: "select",
            id: 'color',
            rules: 'required',
            options: color,

            format: {
              xs: 4,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4
            },
            value: null,
            show: true
          },          

 
          {
            label: "Arranque",
            type: "select",
            id: 'arranque',
            rules: 'required',
            options: [
              { value: null, text: "Selecciona una opción" },
              { value: "Si", text: "Si" },
              { value: "No", text: "No" },
            ],
            format: {
              xs: 4,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4
            },
            value: null,
            show: true
          },
 
          
          

          {
            label: "Restricción Mínimo a producir m2",
            type: "input",
            id: 'restriccion_minimo_a_producir_m2',
            rules: 'required|numeric2',
            tooltip: null,
            format: {
              xs: 4,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4
            },
            value: null
          },

          {
            label: "Restricción Maximo a producir m2",
            type: "input",
            id: 'restriccion_maximo_a_producir_m2',
            rules: 'required|numeric2',
            tooltip: null,
            format: {
              xs: 4,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4
            },
            value: null
          },

          {
            label: "Diseño Rodillo",
            type: "select",
            id: 'diseno_rodillo',
            rules: 'required',
            options: diseno_rodillos,
            format: {
              xs: 4,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4
            },
            value: null,
            show: true
          },

          {
            label: "Engobe",
            type: "select",
            id: 'engobe',
            rules: 'required',
            options: engobe,
            format: {
              xs: 4,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4
            },
            value: null,
            show: true
          },


          {
            label: "Tipo base",
            type: "select",
            id: 'tipo_base',
            rules: 'required',
            options: [
              { value: null, text: "No Aplica" },
              { value: "PIGMENTADA", text: "PIGMENTADA" },
              { value: "NEUTRA", text: "NEUTRA" },
            ],
            format: {
              xs: 4,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4
            },
            value: null,
            show: true
          },

          {
            label: "Nº de Rodillos",
            type: "select",
            id: 'n_rodillos',
            rules: 'required',
            options: [
              { value: null, text: "No Aplica" },
              { value: "0", text: "0" },
              { value: "1", text: "1" },
              { value: "2", text: "2" },
              { value: "3", text: "3" },
              { value: "4", text: "4" },
            ],
            format: {
              xs: 4,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4
            },
            value: null,
            show: true
          },





          //////////

          /*

          {
            label: "Mt2",
            type: "input",
            id: 'mt2',
            rules: 'required|numeric2',
            tooltip: null,
            format: {
              xs: 4,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4
            },
            value: null
          },



          {
            label: "Pasta",
            type: "input",
            id: 'pasta',
            rules: 'required',
            format: {
              xs: 4,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4
            },
            value: null,
            show: true
          },
*/





        ]
      },




    };
  },
  components: {
    BRow,
    BCol,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BButton,
    BCard,
    BCardBody,
    BForm,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BFormDatepicker,
    ToastificationContent,
    vSelect,
    BCardTitle,
    BCardSubTitle,
    VBPopover,
    VBTooltip,
    VueAutosuggest,
    BrunaForm,
  },

  created()
  {


  },

  mounted()
  {




  },


  methods: {



    formatData()
    {

      return {
        nuevo_sku: this.nuevo_sku.fields.reduce((acumulador, field) => { acumulador[field.id] = field.value; return acumulador }, {}),
      }


    },


    createNuevoSku()
    {



      useApiServices.postSkusCreate(this.formatData(), this)
        .then((response) =>
        {

          this.$emit('nuevo-sku-agregado');

          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Carga Exitosa`,
              icon: "UploadIcon",
              variant: "success",
              html: `El nuevo Sku se ha creado exitosamente.`,
            },
          });


        })
        .catch((error) =>
        {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Ocurrio un Error`,
              icon: "UploadIcon",
              variant: "danger",
              html: `Ha ocurrido un error al crear el nuevo Sku</b>.`,
            },
          });

        });





    },


    save()
    {

      console.log(JSON.stringify(this.formatData(), null, 2))

      let self = this;

      Promise.all([
        this.$refs.nuevoSkuRef.validate(),


      ]).then(([nuevoSkuValid]) =>
      {

        if (nuevoSkuValid)
        {
          self.createNuevoSku()
        }

        //console.log({ demandDetailValid, buyerValid, orderValid, skuValid, priceProgramValid, priceSpotValid })



      })


    },



    findFieldByName(fields, name)
    {

      return fields.find(field => field.id == name)

    },





    nuevoSkuChange(data)
    {

    },


  },
};
</script>

<style scoped>
.titulo {
  margin-top: 5%;
}
</style>

<style lang="scss">
.card {
  .card-title {
    margin-bottom: 1.5rem !important;
  }

  ::v-deep .card-header {
    .heading-elements {
      position: static;
      cursor: inherit;

      .list-inline {
        display: block;

        li {
          a {
            padding: 0;
          }

          &:not(:last-child) {
            margin-right: 1rem;
          }
        }
      }
    }
  }
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-autosuggest.scss";
</style>