const familia = [
  { value: null, text: "Selecciona una opción" },
  {value:'ACUARELA',  text:'ACUARELA'},
  {value:'ADOQUIN',  text:'ADOQUIN'},
  {value:'ADOQUIN BEIGE',  text:'ADOQUIN BEIGE'},
  {value:'ADOQUIN GRIS',  text:'ADOQUIN GRIS'},
  {value:'AFRICA',  text:'AFRICA'},
  {value:'AGADIR',  text:'AGADIR'},
  {value:'ALAMO',  text:'ALAMO'},
  {value:'ALAMO_CL',  text:'ALAMO_CL'},
  {value:'ALCAZAR',  text:'ALCAsZAR'},
  {value:'ALDER',  text:'ALDER'},
  {value:'ALELI',  text:'ALELI'},
  {value:'ALISO',  text:'ALISO'},
  {value:'ALMENA',  text:'ALMENA'},
  {value:'ALMENDRO',  text:'ALMENDRO'},
  {value:'ALMERIA_CL',  text:'ALMERIA_CL'},
  {value:'ALMUDENA',  text:'ALMUDENA'},
  {value:'ALPES',  text:'ALPES'},
  {value:'AMANI',  text:'AMANI'},
  {value:'AMERICA',  text:'AMERICA'},
  {value:'AMORE',  text:'AMORE'},
  {value:'ANDY',  text:'ANDY'},
  {value:'ANGELO',  text:'ANGELO'},
  {value:'APOLO',  text:'APOLO'},
  {value:'ARAUCARIA',  text:'ARAUCARIA'},
  {value:'ARCE',  text:'ARCE'},
  {value:'ARDESIA',  text:'ARDESIA'},
  {value:'ARENAM',  text:'ARENAM'},
  {value:'ARUSHA',  text:'ARUSHA'},
  {value:'ASPEN',  text:'ASPEN'},
  {value:'ATENAS',  text:'ATENAS'},
  {value:'ATICA',  text:'ATICA'},
  {value:'ATLANTA',  text:'ATLANTA'},
  {value:'AURA',  text:'AURA'},
  {value:'AURORA',  text:'AURORA'},
  {value:'AVALON',  text:'AVALON'},
  {value:'AVELLANO',  text:'AVELLANO'},
  {value:'BAHIA',  text:'BAHIA'},
  {value:'BENEVENTO',  text:'BENEVENTO'},
  {value:'BENGALA',  text:'BENGALA'},
  {value:'BENGALA_CL',  text:'BENGALA_CL'},
  {value:'BERLIN',  text:'BERLIN'},
  {value:'BIANCO',  text:'BIANCO'},
  {value:'BILBAO',  text:'BILBAO'},
  {value:'BISCOTTO',  text:'BISCOTTO'},
  {value:'BLANCO',  text:'BLANCO'},
  {value:'BLEND SMOOTH FD',  text:'BLEND SMOOTH FD'},
  {value:'BOLONIA',  text:'BOLONIA'},
  {value:'BOSSE',  text:'BOSSE'},
  {value:'BOSTON',  text:'BOSTON'},
  {value:'BOSTON_CL',  text:'BOSTON_CL'},
  {value:'BRECCIA',  text:'BRECCIA'},
  {value:'BRISA',  text:'BRISA'},
  {value:'BRITANIA',  text:'BRITANIA'},
  {value:'BRUSSEL',  text:'BRUSSEL'},
  {value:'BUD',  text:'BUD'},
  {value:'CAIRO',  text:'CAIRO'},
  {value:'CALACATA',  text:'CALACATA'},
  {value:'CALACATTA',  text:'CALACATTA'},
  {value:'CALBUCO_CL',  text:'CALBUCO_CL'},
  {value:'CALI',  text:'CALI'},
  {value:'CALISSA',  text:'CALISSA'},
  {value:'CALISTO',  text:'CALISTO'},
  {value:'CAMERUN',  text:'CAMERUN'},
  {value:'CANARIAS',  text:'CANARIAS'},
  {value:'CANELILLO',  text:'CANELILLO'},
  {value:'CANOVAS',  text:'CANOVAS'},
  {value:'CANVAS',  text:'CANVAS'},
  {value:'CAPRICCIO',  text:'CAPRICCIO'},
  {value:'CARAVISTA',  text:'CARAVISTA'},
  {value:'CARVALO',  text:'CARVALO'},
  {value:'CASABLANCA',  text:'CASABLANCA'},
  {value:'CASTAÑO',  text:'CASTAÑO'},
  {value:'CASTAÑO_CL',  text:'CASTAÑO_CL'},
  {value:'CASTELL',  text:'CASTELL'},
  {value:'CASTELO',  text:'CASTELO'},
  {value:'CAVERNET',  text:'CAVERNET'},
  {value:'CEDRO',  text:'CEDRO'},
  {value:'CEMENTO',  text:'CEMENTO'},
  {value:'CEMENTO G8',  text:'CEMENTO G8'},
  {value:'CEMENTO G8_CL',  text:'CEMENTO G8_CL'},
  {value:'CEMENTTI',  text:'CEMENTTI'},
  {value:'CERDEÑA',  text:'CERDEÑA'},
  {value:'CHIUSA',  text:'CHIUSA'},
  {value:'CIMA',  text:'CIMA'},
  {value:'CIMENTA',  text:'CIMENTA'},
  {value:'CIRUELO',  text:'CIRUELO'},
  {value:'CLASICA',  text:'CLASICA'},
  {value:'COLISEO',  text:'COLISEO'},
  {value:'COMPOSTO',  text:'COMPOSTO'},
  {value:'COMPOSTO_CL',  text:'COMPOSTO_CL'},
  {value:'CONCEPT',  text:'CONCEPT'},
  {value:'CONCRETO',  text:'CONCRETO'},
  {value:'COTTO',  text:'COTTO'},
  {value:'COTTO_CL',  text:'COTTO_CL'},
  {value:'CROACIA',  text:'CROACIA'},
  {value:'CRUZAT',  text:'CRUZAT'},
  {value:'CRYSTAL',  text:'CRYSTAL'},
  {value:'CUADROS_CL',  text:'CUADROS_CL'},
  {value:'CUMARU',  text:'CUMARU'},
  {value:'CUOMO',  text:'CUOMO'},
  {value:'CUOMO_CL',  text:'CUOMO_CL'},
  {value:'DAKOTA',  text:'DAKOTA'},
  {value:'DALI',  text:'DALI'},
  {value:'DALLAS',  text:'DALLAS'},
  {value:'DANELLA',  text:'DANELLA'},
  {value:'DARINKA',  text:'DARINKA'},
  {value:'DECO ART',  text:'DECO ART'},
  {value:'DESERT',  text:'DESERT'},
  {value:'DIAMANTE',  text:'DIAMANTE'},
  {value:'DUCA',  text:'DUCA'},
  {value:'DUELA',  text:'DUELA'},
  {value:'DURCAL',  text:'DURCAL'},
  {value:'DURCAL_CL',  text:'DURCAL_CL'},
  {value:'EBONY',  text:'EBONY'},
  {value:'ELVAS',  text:'ELVAS'},
  {value:'EMPERADOR',  text:'EMPERADOR'},
  {value:'ENIX',  text:'ENIX'},
  {value:'ESPIGA',  text:'ESPIGA'},
  {value:'ESPINO BEIGE',  text:'ESPINO BEIGE'},
  {value:'ESTEFANIA',  text:'ESTEFANIA'},
  {value:'ESTRELLA',  text:'ESTRELLA'},
  {value:'EUROPA',  text:'EUROPA'},
  {value:'EXTRA FORTE',  text:'EXTRA FORTE'},
  {value:'FACHADA',  text:'FACHADA'},
  {value:'FACHALETA',  text:'FACHALETA'},
  {value:'FACHALETA_CL',  text:'FACHALETA_CL'},
  {value:'FANTASY',  text:'FANTASY'},
  {value:'FATMAGUL',  text:'FATMAGUL'},
  {value:'FENICIA',  text:'FENICIA'},
  {value:'FENIX',  text:'FENIX'},
  {value:'FEZ',  text:'FEZ'},
  {value:'FIONA',  text:'FIONA'},
  {value:'FIORE',  text:'FIORE'},
  {value:'FIORI',  text:'FIORI'},
  {value:'FLOOR',  text:'FLOOR'},
  {value:'FLORDITH',  text:'FLORDITH'},
  {value:'FLORENCIA',  text:'FLORENCIA'},
  {value:'FLOWER',  text:'FLOWER'},
  {value:'FRESH',  text:'FRESH'},
  {value:'FRESNO AMERICANO',  text:'FRESNO AMERICANO'},
  {value:'GADOR',  text:'GADOR'},
  {value:'GAETANO',  text:'GAETANO'},
  {value:'GARIBALDI',  text:'GARIBALDI'},
  {value:'GEO CANOVAS',  text:'GEO CANOVAS'},
  {value:'GEO PARAISO',  text:'GEO PARAISO'},
  {value:'GERVASONI',  text:'GERVASONI'},
  {value:'GINEBRA',  text:'GINEBRA'},
  {value:'GIRASOL',  text:'GIRASOL'},
  {value:'GLAMSTONE',  text:'GLAMSTONE'},
  {value:'GRAFIK',  text:'GRAFIK'},
  {value:'GRAN BRETAÑA',  text:'GRAN BRETAÑA'},
  {value:'GRAN BRETAÑA_CL',  text:'GRAN BRETAÑA_CL'},
  {value:'GRANADA',  text:'GRANADA'},
  {value:'GRANITE',  text:'GRANITE'},
  {value:'GRANITO',  text:'GRANITO'},
  {value:'GRAVA',  text:'GRAVA'},
  {value:'GREGAL',  text:'GREGAL'},
  {value:'HABANA',  text:'HABANA'},
  {value:'HADID',  text:'HADID'},
  {value:'HAPPY',  text:'HAPPY'},
  {value:'HAYA',  text:'HAYA'},
  {value:'HERMES',  text:'HERMES'},
  {value:'HIELO',  text:'HIELO'},
  {value:'HIMALAYA',  text:'HIMALAYA'},
  {value:'IBERICA',  text:'IBERICA'},
  {value:'IBERICA_CL',  text:'IBERICA_CL'},
  {value:'INDIGO',  text:'INDIGO'},
  {value:'ITACA',  text:'ITACA'},
  {value:'JACARANDA',  text:'JACARANDA'},
  {value:'JADE',  text:'JADE'},
  {value:'JAEN',  text:'JAEN'},
  {value:'JAIPUR',  text:'JAIPUR'},
  {value:'JAZMIN',  text:'JAZMIN'},
  {value:'JAZZ',  text:'JAZZ'},
  {value:'JAZZ_CL',  text:'JAZZ_CL'},
  {value:'JENNYLU',  text:'JENNYLU'},
  {value:'JUAN REY',  text:'JUAN REY'},
  {value:'KANTU',  text:'KANTU'},
  {value:'KARIBE',  text:'KARIBE'},
  {value:'KATALIA',  text:'KATALIA'},
  {value:'KENTA',  text:'KENTA'},
  {value:'KIARA',  text:'KIARA'},
  {value:'KIARA_CL',  text:'KIARA_CL'},
  {value:'KIARITA',  text:'KIARITA'},
  {value:'LA MAR',  text:'LA MAR'},
  {value:'LAGOS',  text:'LAGOS'},
  {value:'LAJA',  text:'LAJA'},
  {value:'LAJA SCALA',  text:'LAJA SCALA'},
  {value:'LAJA TERRACOTA',  text:'LAJA TERRACOTA'},
  {value:'LARA',  text:'LARA'},
  {value:'LATINA',  text:'LATINA'},
  {value:'LATO',  text:'LATO'},
  {value:'LATO_CL',  text:'LATO_CL'},
  {value:'LEGÑO',  text:'LEGÑO'},
  {value:'LIMARI_CL',  text:'LIMARI_CL'},
  {value:'LINDA',  text:'LINDA'},
  {value:'LINEAL',  text:'LINEAL'},
  {value:'LISO_CL',  text:'LISO_CL'},
  {value:'LIVING',  text:'LIVING'},
  {value:'LODGE',  text:'LODGE'},
  {value:'LORENZO',  text:'LORENZO'},
  {value:'LOUVRE',  text:'LOUVRE'},
  {value:'LUCAR',  text:'LUCAR'},
  {value:'LUCIENNE',  text:'LUCIENNE'},
  {value:'LUMIERE',  text:'LUMIERE'},
  {value:'LUXURY',  text:'LUXURY'},
  {value:'MACARENA',  text:'MACARENA'},
  {value:'MACHI',  text:'MACHI'},
  {value:'MADEIRA',  text:'MADEIRA'},
  {value:'MADERA CIPRES',  text:'MADERA CIPRES'},
  {value:'MADERA MURCIA_CL',  text:'MADERA MURCIA_CL'},
  {value:'MADERA TARIMA',  text:'MADERA TARIMA'},
  {value:'MADERA_CL',  text:'MADERA_CL'},
  {value:'MAGIC',  text:'MAGIC'},
  {value:'MAIRENA',  text:'MAIRENA'},
  {value:'MALLORCA',  text:'MALLORCA'},
  {value:'MAPLE',  text:'MAPLE'},
  {value:'MARA',  text:'MARA'},
  {value:'MARMENELLI',  text:'MARMENELLI'},
  {value:'MARMI',  text:'MARMI'},
  {value:'MARMOL AFRODITA',  text:'MARMOL AFRODITA'},
  {value:'MARMOL ATENEA',  text:'MARMOL ATENEA'},
  {value:'MARMOL BLANCO',  text:'MARMOL BLANCO'},
  {value:'MARMOL IBIZA',  text:'MARMOL IBIZA'},
  {value:'MARMOL PANDORA',  text:'MARMOL PANDORA'},
  {value:'MARMOLANATO OSIRIS',  text:'MARMOLANATO OSIRIS'},
  {value:'MASARIK',  text:'MASARIK'},
  {value:'MATISSE',  text:'MATISSE'},
  {value:'MATRIX',  text:'MATRIX'},
  {value:'MIEL',  text:'MIEL'},
  {value:'MIKONOS',  text:'MIKONOS'},
  {value:'MILA',  text:'MILA'},
  {value:'MILAN',  text:'MILAN'},
  {value:'MILANO',  text:'MILANO'},
  {value:'MIRATO',  text:'MIRATO'},
  {value:'MISTI',  text:'MISTI'},
  {value:'MISTRAL',  text:'MISTRAL'},
  {value:'MITHOS',  text:'MITHOS'},
  {value:'MIX COLOR',  text:'MIX COLOR'},
  {value:'MIX COLOR_CL',  text:'MIX COLOR_CL'},
  {value:'MOKA',  text:'MOKA'},
  {value:'MOODS',  text:'MOODS'},
  {value:'MOSAICO',  text:'MOSAICO'},
  {value:'MOSAIK',  text:'MOSAIK'},
  {value:'MURCIA',  text:'MURCIA'},
  {value:'NAPOLI',  text:'NAPOLI'},
  {value:'NATALIA',  text:'NATALIA'},
  {value:'NATIVA',  text:'NATIVA'},
  {value:'NAVY',  text:'NAVY'},
  {value:'NIEVE',  text:'NIEVE'},
  {value:'NOBLESSE',  text:'NOBLESSE'},
  {value:'NORDICA',  text:'NORDICA'},
  {value:'OCAÑA',  text:'OCAÑA'},
  {value:'ODEON',  text:'ODEON'},
  {value:'OLIMPIA',  text:'OLIMPIA'},
  {value:'OLIVILLO',  text:'OLIVILLO'},
  {value:'ONICE',  text:'ONICE'},
  {value:'ONIX',  text:'ONIX'},
  {value:'OPERA',  text:'OPERA'},
  {value:'ORQUIDEAS',  text:'ORQUIDEAS'},
  {value:'OXFORD',  text:'OXFORD'},
  {value:'OXIDO',  text:'OXIDO'},
  {value:'OXIDUM',  text:'OXIDUM'},
  {value:'PAHALA',  text:'PAHALA'},
  {value:'PALADIO',  text:'PALADIO'},
  {value:'PARACAS',  text:'PARACAS'},
  {value:'PARAISO',  text:'PARAISO'},
  {value:'PARKETON',  text:'PARKETON'},
  {value:'PARKETON ABBEY',  text:'PARKETON ABBEY'},
  {value:'PARKETON EXCELLENCE',  text:'PARKETON EXCELLENCE'},
  {value:'PARKETON GOLDEN',  text:'PARKETON GOLDEN'},
  {value:'PARKETON INWOOD',  text:'PARKETON INWOOD'},
  {value:'PARKETON LEGÑO',  text:'PARKETON LEGÑO'},
  {value:'PARKETON OLYMPIA',  text:'PARKETON OLYMPIA'},
  {value:'PARKETON PREMIUM',  text:'PARKETON PREMIUM'},
  {value:'PARMA',  text:'PARMA'},
  {value:'PARQUET',  text:'PARQUET'},
  {value:'PARQUET CEDRO',  text:'PARQUET CEDRO'},
  {value:'PARQUETON',  text:'PARQUETON'},
  {value:'PARQUETON_CL',  text:'PARQUETON_CL'},
  {value:'PASSION',  text:'PASSION'},
  {value:'PASTELERO',  text:'PASTELERO'},
  {value:'PATAGUA',  text:'PATAGUA'},
  {value:'PAULINA',  text:'PAULINA'},
  {value:'PEHUEN',  text:'PEHUEN'},
  {value:'PEPELMA',  text:'PEPELMA'},
  {value:'PERSA',  text:'PERSA'},
  {value:'PERU FORTE',  text:'PERU FORTE'},
  {value:'PETRA',  text:'PETRA'},
  {value:'PETRUS',  text:'PETRUS'},
  {value:'PIEDRA',  text:'PIEDRA'},
  {value:'PIETRA',  text:'PIETRA'},
  {value:'PIEZAS DECORATIVAS',  text:'PIEZAS DECORATIVAS'},
  {value:'PINOT',  text:'PINOT'},
  {value:'PISCINA',  text:'PISCINA'},
  {value:'PLAIN',  text:'PLAIN'},
  {value:'POAS',  text:'POAS'},
  {value:'PORCELAIN',  text:'PORCELAIN'},
  {value:'PORTO',  text:'PORTO'},
  {value:'POSEIDON',  text:'POSEIDON'},
  {value:'PRAGA',  text:'PRAGA'},
  {value:'PRISMA',  text:'PRISMA'},
  {value:'PRISMA_CL',  text:'PRISMA_CL'},
  {value:'PUYEHUE',  text:'PUYEHUE'},
  {value:'QATAR',  text:'QATAR'},
  {value:'RAKKI',  text:'RAKKI'},
  {value:'RETTANGOLO',  text:'RETTANGOLO'},
  {value:'RETTANGOLO_CL',  text:'RETTANGOLO_CL'},
  {value:'REYNA',  text:'REYNA'},
  {value:'REZZATO',  text:'REZZATO'},
  {value:'ROCKSTONE',  text:'ROCKSTONE'},
  {value:'ROCKSTONE_CL',  text:'ROCKSTONE_CL'},
  {value:'RUPANCO',  text:'RUPANCO'},
  {value:'RUSIA',  text:'RUSIA'},
  {value:'SAN MARCOS',  text:'SAN MARCOS'},
  {value:'SCALENO',  text:'SCALENO'},
  {value:'SEGOVIA',  text:'SEGOVIA'},
  {value:'SESLA',  text:'SESLA'},
  {value:'SEVILLA',  text:'SEVILLA'},
  {value:'SILA',  text:'SILA'},
  {value:'SILEA',  text:'SILEA'},
  {value:'SILEA_CL',  text:'SILEA_CL'},
  {value:'SILVESTRE',  text:'SILVESTRE'},
  {value:'SIROCCO',  text:'SIROCCO'},
  {value:'SKY',  text:'SKY'},
  {value:'SOFIA',  text:'SOFIA'},
  {value:'SOFT',  text:'SOFT'},
  {value:'SOLANA',  text:'SOLANA'},
  {value:'SOLINA',  text:'SOLINA'},
  {value:'STONES',  text:'STONES'},
  {value:'STRONG',  text:'STRONG'},
  {value:'STYLUS',  text:'STYLUS'},
  {value:'SWING',  text:'SWING'},
  {value:'SYLVANA',  text:'SYLVANA'},
  {value:'TABLA',  text:'TABLA'},
  {value:'TABLILLA',  text:'TABLILLA'},
  {value:'TAIPEI',  text:'TAIPEI'},
  {value:'TAPARELLA',  text:'TAPARELLA'},
  {value:'TARIMA',  text:'TARIMA'},
  {value:'TEMU',  text:'TEMU'},
  {value:'TEXTIL LINO',  text:'TEXTIL LINO'},
  {value:'THALIA',  text:'THALIA'},
  {value:'TIFFANY',  text:'TIFFANY'},
  {value:'TILO',  text:'TILO'},
  {value:'TIZIANO',  text:'TIZIANO'},
  {value:'TOLEDO',  text:'TOLEDO'},
  {value:'TOMASSO',  text:'TOMASSO'},
  {value:'TOSCANA',  text:'TOSCANA'},
  {value:'TRAMONTANA',  text:'TRAMONTANA'},
  {value:'TRAVERTINI',  text:'TRAVERTINI'},
  {value:'TRAVERTINO',  text:'TRAVERTINO'},
  {value:'TRIANA',  text:'TRIANA'},
  {value:'TRINITY',  text:'TRINITY'},
  {value:'TURRE',  text:'TURRE'},
  {value:'TURRI',  text:'TURRI'},
  {value:'URBAN',  text:'URBAN'},
  {value:'URBANO',  text:'URBANO'},
  {value:'URBIS',  text:'URBIS'},
  {value:'VALLADOLID',  text:'VALLADOLID'},
  {value:'VANGUARD',  text:'VANGUARD'},
  {value:'VENETO',  text:'VENETO'},
  {value:'VENETO_CL',  text:'VENETO_CL'},
  {value:'VENETTO',  text:'VENETTO'},
  {value:'VENICE',  text:'VENICE'},
  {value:'VICAR',  text:'VICAR'},
  {value:'VICAR_CL',  text:'VICAR_CL'},
  {value:'VICENZA',  text:'VICENZA'},
  {value:'VITAL',  text:'VITAL'},
  {value:'VITTORIO',  text:'VITTORIO'},
  {value:'VOLTA',  text:'VOLTA'},
  {value:'VULCANO',  text:'VULCANO'},
  {value:'WALNUT',  text:'WALNUT'},
  {value:'WASHI',  text:'WASHI'},
  {value:'WHITE',  text:'WHITE'},
  {value:'WOOD',  text:'WOOD'},
  {value:'WOODRIVER',  text:'WOODRIVER'},
  {value:'YACILA',  text:'YACILA'},
  {value:'YAULI',  text:'YAULI'},
  {value:'ZAFIO',  text:'ZAFIO'},
  {value:'ZAGORA',  text:'ZAGORA'},
  {value:'ZEBRON',  text:'ZEBRON'},
  {value:'ZOCATEA',  text:'ZOCATEA'}
];

export { familia };