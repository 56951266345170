const engobe = [
  { value: null, text: 'No Aplica' },
  {value:'ENGOC310001', text: 'ENGOC310001'},
  {value:'ENGOC310002', text: 'ENGOC310002'},
  {value:'ENGON310001', text: 'ENGON310001'},
  {value:'ENGON310004', text: 'ENGON310004'},
  {value:'ENGON310006', text: 'ENGON310006'},
  {value:'ENGON310007', text: 'ENGON310007'},
  {value:'ENGON310009', text: 'ENGON310009'},
  {value:'ENGON310010', text: 'ENGON310010'},
  {value:'ENGON310012', text: 'ENGON310012'},
  {value:'ENGON310013', text: 'ENGON310013'},

];

export { engobe };